import React from 'react';

function ContactPage() {
  return (
    <div className="page">
      <h2>Contact</h2>
      <p>You can message me on any of the below social platform with questions, comments, requests etc.
        <br></br>
        My only ask is that you be respectful and remember that I have a life outside my job and career.
        <br></br>
        <br></br>
        <a href="https://www.linkedin.com/in/aarav-sinha-749047159/" target="_blank">LinkedIn</a>
     <br></br><a href="https://x.com/aarav__avi" target="_blank">Twitter</a>
     <br></br><a href="mailto:connect@aaravsinha.in">Email</a>
      </p>
    </div>
  );
}

export default ContactPage;
