import React from 'react'
import "./PageHero.css"
import textImage from '../../../../logo.svg'

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ@#^&!"

const TextAnimation = (event) =>{
    let itrations = 0;
    const text = event.target.dataset.value
    const interval = setInterval(()=>{
        event.target.innerText = event.target.innerText.split("")
        .map((letter, index) => {
            if(index < itrations){
                return text[index]
            }
            return letters[Math.floor(Math.random() * letters.length)]
        })
        .join("")

        if(itrations >= text.length) clearInterval(interval)

        itrations += 1;
    },70)
}

const PageHero = () => {
  return ( 
    <div className='HeroParent'>
        <img className='PlaceHolder_Image' src= "https://i0.wp.com/lachieslifestyle.com/wp-content/uploads/2023/05/hacker-dark-room.png?fit=1024%2C574&ssl=1" style={{ padding: "10px" }}></img>
        <div className='HeroChild-UserInfo'>
            <h1 
                data-value = "AARAV SINHA"
                className='HeadLine'
                onMouseOver={TextAnimation}    
            >AARAV SINHA</h1>
            <p>
            Hello! I have almost half a decade worth of experience in Offensive security. I currently specialise in Application security with a passion for keeping the digital word safe for use.
        I have a strong background in security fundamentals and have extensive experience with real life scenarios involving mobile, cloud and web applications.
        <br></br>
        I love the daily challenges the blooming digital world presents and the ability to be creative when solving complex problems is a huge driving force for me.
        <br></br>
        <br></br>
        Outside of work I like sports, music, video games, traveling and COFFEE (in no specific order).
            </p>
        </div>
    </div>
  )
}

export default PageHero