import React from 'react'
import Terminal from 'react-console-emulator'
import './Terminal.css'

const commands = {
  About: {
      description: 'About me',
      usage: '',
      fn: (...args) => {
        return (
          <>
            <h2>
            Hello! I have almost half a decade worth of experience in Offensive security. I currently specializes in Application security with a passion for keeping the digital word safe for use.
        I have a strong background in security fundamentals and have extensive experience with real life scenarios involving mobile, cloud and web applications.
        I love the daily challenges the blooming digital world presents and the ability to be creative when solving complex problems is a huge driving force for me.

        Outside of work I like sports, music, video games, traveling and COFFEE (in no specific order).
            </h2>
          </>
        )
      }
    },

    Skills:{
      description : 'Shows my skills',
      usage : '',
      fn: (...args) =>{
        return (
          <>
            <ul>
                <li>OWASP Top 10 (Web and Mobile)</li>
                <li>Cloud Infrastructure Security</li>
                <li>Compliance Security</li>
                <li>Compliance security</li>
                <li>Coding and Scripting</li>
                <li>Wireless Security (WiFi and BLE)</li>
            </ul>
          </>
        )
      }
    },

    Certifications:{
      description: 'Shows list of credentials',
      usage: '',
      fn: (...args) => {
          return (
            <>
              <ul>
                  <li>OSCP+ (offsec)</li>
                  <li>NSE 2 (Fortinet)</li>
                  <li>Microsoft Security Fundamentals (Microsoft)</li>
              </ul>
            </>
          )
      }
    },

    Achievements:{
        description: 'Shows list of achievements',
        usage: '',
        fn: (...args) => {
            return (
              <>
                <ul>
                    <li>CVE-2024-25272, CVE-2024-25271</li>
                    <li>CVE-2024-24143</li>
                    <li>First place, Bosch global CTF 2022</li>
                    <li>Bravo Award, Bosch 2022</li>
                    <li>Best Tyro, Bosch 2021</li>
                </ul>
              </>
            )
        }
    }

  }

const Terminalreact = () => {
  return (
    <div className='react_Terminal'>
      <Terminal
        commands={commands}
        welcomeMessage={'For more information type help in terminal'}
        promptLabel={'AARAV_SINHA:~$'}
      />
    </div> 
  )
}

export default Terminalreact